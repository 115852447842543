<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} hostel room` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cancelForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="hostelRoom.title"
                                outlined
                                dense
                                :error="$v.hostelRoom.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelRoom.title.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="hostelRoom.hostel_id"
                                outlined
                                @change="handelHostelChange"
                                dense
                                :items="hostels"
                                item-text="title"
                                item-value="id"
                                :error="$v.hostelRoom.hostel_id.$error"
                            >
                                <template v-slot:label>
                                    Hostel <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.hostelRoom.hostel_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-autocomplete
                                v-model="hostelRoom.hostel_building_id"
                                outlined
                                dense
                                :items="hostelBuildings"
                                :loading="hostelBuildingLoading"
                                item-text="title"
                                item-value="id"
                                :error="$v.hostelRoom.hostel_building_id.$error"
                            >
                                <template v-slot:label>
                                    Hostel building<span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.hostelRoom.hostel_building_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Description <span class="text-danger"></span>
                            </label>
                            <ckeditor v-model="hostelRoom.description" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.hostelRoom.description.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Amenities <span class="text-danger"></span>
                            </label>
                            <ckeditor v-model="hostelRoom.amenities" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.hostelRoom.amenities.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="3" class="mt-3">
                            <v-text-field
                                v-model="hostelRoom.no_of_beds"
                                outlined
                                type="number"
                                dense
                                :error="$v.hostelRoom.no_of_beds.$error"
                            >
                                <template v-slot:label>
                                    Number of beds<span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelRoom.no_of_beds.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="3" class="mt-3">
                            <v-select
                                v-model="hostelRoom.fee_frequency"
                                outlined
                                :items="feeFrequencies"
                                item-text="name"
                                item-value="value"
                                dense
                                :error="$v.hostelRoom.fee_frequency.$error"
                            >
                                <template v-slot:label>
                                    Fee frequency<span class="text-danger">*</span>
                                </template>
                            </v-select>
                            <span class="text-danger" v-if="$v.hostelRoom.fee_frequency.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="3" class="mt-3">
                            <v-text-field
                                v-model="hostelRoom.fee"
                                outlined
                                type="number"
                                dense
                                :error="$v.hostelRoom.fee.$error"
                            >
                                <template v-slot:label>
                                    Fee<span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelRoom.fee.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="3" class="mt-3">
                            <v-text-field
                                v-model="hostelRoom.max_discount_allowed"
                                outlined
                                type="number"
                                dense
                                :error="$v.hostelRoom.max_discount_allowed.$error"
                            >
                                <template v-slot:label>
                                    Max discount allowed<span class="text-danger"></span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelRoom.max_discount_allowed.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Status
                            </label>
                            <v-switch v-model="hostelRoom.is_active" :label="hostelRoom.is_active ? 'Active' : 'Inactive'"></v-switch>
                            <span class="text-danger" v-if="$v.hostelRoom.is_active.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="cancelForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn
                                    v-if="checkIsAccessible('gate-pass', 'create')"
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                    v-if="checkIsAccessible('hostel-room', 'create') && checkIsAccessible('hostel-room', 'edit')"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import HostelBuildingService from "@/core/services/hostel/hostel-building/HostelBuildingService";
import HostelService from "@/core/services/hostel/HostelService";
import HostelRoomService from "@/core/services/hostel/hostel-room/HostelRoomService";

const hostelBuilding = new HostelBuildingService();
const hostel = new HostelService();
const hostelRoom = new HostelRoomService();

export default {
    validations: {
        hostelRoom: {
            hostel_id: {required},
            hostel_building_id: {required},
            title: { required },
            no_of_beds: {required},
            description: {},
            amenities: {},
            fee_frequency: {required},
            fee: {required},
            max_discount_allowed: {},
            is_active: {}
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            hostelRoom: {
                hostel_id: null,
                hostel_building_id: null,
                title:  '',
                no_of_beds: null,
                description: '',
                amenities: '',
                fee_frequency: '',
                fee: null,
                max_discount_allowed: null,
                is_active: true,
            },
            hostelBuildingLoading: false,
            hostels: [],
            hostelBuildings: [],
            editorConfig: {
                versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            feeFrequencies: [
                {
                    name: 'Weekly',
                    value: 'weekly',
                },
                {
                    name: 'Monthly',
                    value: 'monthly',
                },
                {
                    name: 'Quarterly',
                    value: 'quarterly',
                },
                {
                    name: 'Yearly',
                    value: 'yearly',
                },
            ]
        };
    },
    methods: {
        showModal(id = null) {
            this.getHostels();
            if (id) {
                this.edit = true
                this.getHostelRoom(id)
            }else{
                this.resetForm();
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        cancelForm(){
            this.resetForm();
            this.hideModal();
        },
        handelHostelChange(){
            if(this.hostelRoom.hostel_id){
                this.getHostelBuildings()
            }else{
                this.hostelBuildings = [];
                this.hostelRoom.hostel_building_id = null;
            }
        },
        getHostelRoom(id) {
            hostelRoom
            .show(id)
            .then(response => {
                this.hostelRoom = response.data.hostelRoom;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.hostelRoom.$touch();
            if (this.$v.hostelRoom.$error) {
                setTimeout(() => {
                    this.$v.hostelRoom.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateHostelRoom();
                } else {
                    this.createHostelRoom();
                }
            }
        },
        updateHostelRoom() {
            this.isBusy = true;
            hostelRoom
            .update(this.hostelRoom.id, this.hostelRoom)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
                this.hideModal();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createHostelRoom() {
            this.isBusy = true;
            hostelRoom
            .store(this.hostelRoom)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.hideModal();
                this.$emit("refresh");
                this.$snotify.success("Information added");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.hostelRoom.$reset();
            this.edit = false;
            this.hostelRoom = {
                hostel_id: null,
                hostel_building_id: null,
                title:  '',
                no_of_beds: null,
                description: '',
                amenities: '',
                fee_frequency: '',
                fee: null,
                max_discount_allowed: null,
                is_active: true,
            };
        },
        getHostels(){
            hostel
            .all()
            .then((res) => {
                this.hostels = res.data.hostels;
            })
            .catch(err => {

            });
        },
        getHostelBuildings(){
            this.hostelBuildingLoading = true;
            hostelBuilding
            .getByHostel(this.hostelRoom.hostel_id)
            .then((res) => {
                this.hostelBuildings = res.data;
                this.hostelBuildingLoading = false;
            })
            .catch(err => {

            });
        }
    }
}
</script>
